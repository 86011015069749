<template>
 <div :class="windowClass">
   <v-container>
        <v-toolbar dense>
          <v-icon left>mdi-chart-tree</v-icon> Bets 
          <v-spacer/>
               <span style="width: 200px; margin-top: 24px;"> 
                     <v-menu
                        v-model="from_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="From Date"
                                 :value="from_date"
                                 filled
                                 readonly
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="from_date" no-title scrollable @input="from_menu=false" />
                    </v-menu>
               </span>
                <span style="width: 200px; margin-top: 24px;" class="ml-2"> 
                     <v-menu
                        v-model="to_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                                label="To Date"
                                 :value="to_date"
                                 readonly
                                 filled
                                dense
                                outlined
                                append-icon="mdi-calendar"
                                v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="to_date" no-title scrollable @input="to_menu=false, getBets()" />
                    </v-menu>
               </span>
            
        </v-toolbar>

        <v-row>
            <v-col cols="12">
                 <table class="va-table md-font mt-5" id="over-table">
                    <tr>
                      <td  class="border_bot">BETTOR</td>
                        <td  class="border_bot">FIGHT</td>
                        <td  class="border_bot">BET</td>
                        <td  class="border_bot text-center">STATUS</td>
                        <td class="text-right border_bot">AMOUNT</td>
                    </tr>
                    <tr v-for="(item, index) in bets" :key="index">
                       <td  class="border_bot">{{item.bettor_name}}</td>
                        <td  class="border_bot">#{{item.fight_no}} = <span :class="'text-' + item.result.toLowerCase()">{{item.result}}</span>
                          <br/>
                          <small>{{$moment(item.bet_dt).format("MMM DD, hh:mm A")}}</small>
                        </td>
                         <td  class="border_bot"> {{$money(item.amount)}} <span :class="'text-' + item.bet_to.toLowerCase()"> to {{item.bet_to}}</span></td>
                          <td  class="border_bot text-center">{{valStatus(item.status, item.status)}}</td>
                        <td class="text-right border_bot" :class="'text-' + valStatus(item.status, item.status)">
                          {{$money(item.status == 'Win'?item.win:item.amount)}}
                          </td>
                    </tr>
               
                </table>
            </v-col>
        </v-row>
   </v-container>
  </div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
      bets:[],
      areas:[],
    from_menu: false,
        to_menu: false,
        from_date: '',
        to_date: '',
      
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
         this.from_date = this.$moment().format("YYYY-MM-DD")
          this.to_date = this.$moment().format("YYYY-MM-DD")
          this.getBets()
        } 
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
    
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
       valStatus(result,status) {
        if(result) {
            if(status == "Submitted") {
              return "Posted"
            } else {
              return status
            }
        } else {
           return "Ongoing fight"
        }
      },
       getBets(){
         this.bets = []
          this.$http.post("report/query_bets",{from_date:this.from_date, to_date: this.to_date}).then(response => {
                    response.data.items != null?this.bets = response.data.items:this.bets=[]
                }).catch(e => {
                 console.log(e.data)
             })
      },
       
    }
  }
</script>